/**
 * Advertiser login action. By default it is advertiser home page.
 */
var advertiserLoginAction = function() {
    location.href = ksAdvHomeLink;
};

/**
 * Set advertiser login action. The action could be a function, or url or empty
 * @param action
 */
function setAdvertiserLoginAction(action) {
    // Check if provided argument is a valid callback function then set it
    if ( typeof action === 'function' ) {
        advertiserLoginAction = action;
    }

    // Else if it is a url to go to, then use it
    else if ( typeof action !== 'undefined' && action.indexOf( '//' ) > -1 ) {
        advertiserLoginAction = function () {
            location.href = action;
        }
    }

    // Else set it to advertiser home
    else {
        advertiserLoginAction = function () {
            location.href = ksAdvHomeLink;
        }
    }
}

/**
 * If logged in, then take to member dashboard page, otherwise show login/register dialog
 */
function advertiserArea(callbackFunction)
{
    // Set login action
    setAdvertiserLoginAction(callbackFunction);

    // Check if logged in already
    if ( ksAdvToken != '' ) {
        advertiserLoginAction();
    }

    // If not then show the login modal
    else {
        $('#idModalLoginReg').modal({
            show: true
        });
    }
}

/**
 * Advertiser login submit handler
 * @param frm
 * @returns {boolean}
 */
function advertiserLoginSubmit(frm)
{
    // Send ajax login request
    $.ajax({
        url: frm.action,
        method: 'post',
        data: $(frm).serialize(),
        success: function (response) {
            console.log(response);
            if ( 'ok' == response.status ) {
                ksAdvToken = response.token;
                advertiserLoginAction();
            }
            else {
                showErrorMsgIn( '#id-login-form-msgs', response.error );
            }
        }
    });

    // Cancel form submit
    return false;
}

/**
 * Advertiser registeration submit handlers
 * @param frm
 * @returns {boolean}
 */
function advertiserRegisterSubmit(frm)
{
    // Send ajax login request
    $.ajax({
        url: frm.action,
        method: 'post',
        data: $(frm).serialize(),
        success: function (response) {
            console.log(response);
            if ( 'ok' == response.status ) {
                ksAdvToken = response.token;
                advertiserLoginAction();
            }
            else {
                showErrorMsgIn( '#id-reg-form-msgs', response.error );
            }
        }
    });

    // Cancel form submit
    return false;
}

/**
 * Called when advertiser is logged out
 */
function advertiserLoggedOut() {
    showGrawlMessage('Your are successfully logged out!', 'success');
    window.setTimeout(function () {
        location.href = ksSiteHomeLink;
    }, 1000);
}

/**
 * Initialize facebook application
 * @param fbAppId
 */
function initializeFacebook(fbAppId)
{
    window.fbAsyncInit = function() {
        FB.init({
            appId      : fbAppId,
            xfbml      : true,
            version    : 'v2.9'
        });
        FB.AppEvents.logPageView();
    };

    (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
}

// Save the ajax endpoint url for facebook operations
var ksAdvAjaxFbLoginEndPoint = '';
/**
 * Login with Facebook
 * @param ajaxUrl is the end-point url
 */
function loginWithFacebook(ajaxUrl)
{
    // Save the ajax endpoint url for facebook operations
    ksAdvAjaxFbLoginEndPoint = ajaxUrl;

    // Show progres
    showErrorMsgIn( '#id-login-form-msgs', 'Connecting with Facebook... Please wait!' );

    // Try connection now
    FB.getLoginStatus(function(response) {
        //console.log(response);
        if (response.status === 'connected') {
            loginWithFacebookStatusChanged(response);
        }
        else {
            FB.login(function (response) {
                loginWithFacebookStatusChanged(response);
            }, {
                scope:'email'
            });
        }
    });
}

/**
 * Facebook login status change event
 * @param response
 */
function loginWithFacebookStatusChanged(response) {
    // Get data received from facebook
    fbuid = response.authResponse.userID;
    fbacctkn = response.authResponse.accessToken;

//    console.log(response);

    // If not valid, then show message and exit
    if ( response.status !== 'connected' || '' == fbuid || '' ==  fbacctkn ) {
        console.log(response);
        showErrorMsgIn( '#id-login-form-msgs', 'Facebook login failed, please try again.' );
        return;
    }

    // Use facebook data to login
    showErrorMsgIn( '#id-login-form-msgs', 'Checking your account with us using Facebook...' );
    makeAjaxCall({
        url: ksAdvAjaxFbLoginEndPoint,
        method: 'get',
        data: 'act=fblogin&fbuid='+fbuid+'&fbacctkn='+fbacctkn+'&'
    });
}

/**
 * Facebook login ajax return handler
 * @param response
 */
function loginWithFacebookAjaxReturn(response) {
    // Check if next action parameter is provided
    if ( typeof response.call === 'undefined' ) {
        showErrorMsgIn( '#id-login-form-msgs', 'Facebook login failed, please try again' );
        return;
    }

    // If logged in by facebook
    if ( 'loggedin' == response.call ) {
        showErrorMsgIn( '#id-login-form-msgs', 'Logged in with Facebook... Please wait!' );
        ksAdvToken = response.token;
        advertiserLoginAction();
    }

    // If not found, then try to register
    if ( 'fbreg' == response.call ) {
        showErrorMsgIn( '#id-login-form-msgs', 'Regsitering with Facebook... Please wait!' );
        makeAjaxCall({
            url: ksAdvAjaxFbLoginEndPoint,
            method: 'get',
            data: 'act=fbreg&fbacctkn='+response.fbacctkn+'&'
        });
    }
}

/**
 * Make an ajax call as setup in parameters and do the action as asked in response
 * @param params
 */
function makeAjaxCall(params) {
    // Initialize and check params
    if ( typeof params !== 'object' || typeof params['url'] === 'undefined' ) {
        console.log('Params for ajax call not good: '+params);
        return;
    }
    params.method = typeof params['method'] === 'undefined' ? 'get' : params.method;
    params.data = typeof params['data'] === 'undefined' ? '' : params.data;

    // Send ajax call
    $.ajax({
        url: params.url,
        method: params.method,
        data: params.data,
        success: function (response) {
            console.log(response);
            if ( 'ok' == response.status ) {
               if ( 'redirect' == response.action ) {
                   location.href = response.url;
               }
               else if ( 'js-func' == response.action ) {
                   window[response.function_to_call](response)
               }
               else {
                   console.log( response );
               }
            }
            else if ( typeof response.error !== 'undefined' ) {
                alert( response.error );
            }
            else {
                console.log( response );
            }
        }
    });
}

/**
 * Show a grawl message on page
 * @param msg string message to show
 * @param type string type: info, success, danger
 */
function showGrawlMessage(msg, type)
{
    type = typeof type === 'undefined' ? 'info' : type;

    $.notify({
        // options
        message: msg
    },{
        // settings
        type: type
    });
}

/**
 * Show alert message in the given selector location
 * @param selector
 * @param msg
 */
function showErrorMsgIn(selector, msg)
{
    var message = $('<div class="alert alert-danger" style="display: none;">');
    var close = $('<button type="button" class="close" data-dismiss="alert">&times</button>');
    message.append(close);
    message.append(msg);
    $(selector).html('');
    message.appendTo($(selector)).fadeIn(300);
}

// Run script when page is loaded
$(function() {

    // Show page notification if it is set
    ksPageInfoMsg = typeof ksPageInfoMsg === 'undefined' ? '' : ksPageInfoMsg;
    ksPageInfoType = typeof ksPageInfoType === 'undefined' || ksPageInfoType == '' ? 'info' : ksPageInfoType;
    if ( ksPageInfoMsg != '' ) {
        showGrawlMessage( ksPageInfoMsg, ksPageInfoType );
    }

});

